import {
  CONFIRM_INSTANT_CHECKOUT,
  STARTED_INSTANT_CHECKOUT,
  FINISHED_INSTANT_CHECKOUT,
} from './types';

export const confirmInstantCheckout = (): WtrAction => ({
  type: CONFIRM_INSTANT_CHECKOUT,
});

export const startedInstantCheckout = (): WtrAction => ({
  type: STARTED_INSTANT_CHECKOUT,
});

export const finishedInstantCheckout = (): WtrAction => ({
  type: FINISHED_INSTANT_CHECKOUT,
});
